
import React, { useState } from 'react';
import { sliceDf } from '../utils/dataManipulation'; 

function TelegramDashboard() {
    // State to manage the downloading status
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

    // State variables for the filter inputs
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');
    const [chatGroup, setChatGroup] = useState('');
    const [freeWords, setFreeWords] = useState('');

    // State to manage the visibility of the filter popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // Function to handle the download of the CSV file
    const handleCsvDownload = () => {
        setIsDownloadingCsv(true);

        try {
            const csvFileUrl = `${process.env.PUBLIC_URL}/telegram_v1.csv`;
            const a = document.createElement('a');
            a.href = csvFileUrl;
            a.download = 'telegram_v1.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error("Error downloading the CSV file:", error);
        } finally {
            setIsDownloadingCsv(false);
        }
    };

    // Function to handle the Get Data button click
    const handleGetData = () => {
        // Log the filter values (you can replace this with your own logic)
        console.log({ fromTime, toTime, chatGroup, freeWords });
        // ========================================================
        // HERE
        sliceDf(fromTime, toTime, chatGroup, freeWords );
        // ========================================================

        // Close the popup after getting data
        setIsPopupOpen(false);
    };

    return (
        <div>
            {/* Button that triggers the CSV download process */}
            <button onClick={handleCsvDownload} disabled={isDownloadingCsv}>
                {isDownloadingCsv ? 'Downloading CSV...' : 'Download Telegram CSV'}
            </button>
            {isDownloadingCsv && <p>Please wait, your CSV file is being downloaded...</p>}

            {/* Button to open the filter popup */}
            <button onClick={() => setIsPopupOpen(true)}>Open Filters</button>

            {/* Popup Modal for Filters */}
            {isPopupOpen && (
                <div className="popup-modal">
                    <h2>Filters</h2>
                    <div>
                        <label>
                            From Time:
                            <input
                                type="datetime-local"
                                value={fromTime}
                                onChange={(e) => setFromTime(e.target.value)}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            To Time:
                            <input
                                type="datetime-local"
                                value={toTime}
                                onChange={(e) => setToTime(e.target.value)}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            Chat Group:
                            <input
                                type="text"
                                value={chatGroup}
                                onChange={(e) => setChatGroup(e.target.value)}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            Free Words:
                            <input
                                type="text"
                                value={freeWords}
                                onChange={(e) => setFreeWords(e.target.value)}
                            />
                        </label>
                    </div>
                    <button onClick={handleGetData}>Get Data</button>
                    <button onClick={() => setIsPopupOpen(false)}>Close</button>
                </div>
            )}
        </div>
    );
}

export default TelegramDashboard;

// ===============================================================
// import React, { useState } from 'react';

// function TelegramDashboard() {
//     // State to manage the downloading status
//     const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

//     // Function to handle the download of the CSV file
//     const handleCsvDownload = () => {
//         // Indicate that the CSV download is in progress
//         setIsDownloadingCsv(true);

//         try {
//             // Specify the URL of the CSV file
//             const csvFileUrl = `${process.env.PUBLIC_URL}/telegram_v1.csv`;
            
//             // Create a temporary link element for downloading the CSV file
//             const a = document.createElement('a');
//             a.href = csvFileUrl; // Set the href to the CSV file URL
//             a.download = 'telegram_v1.csv'; // Set the desired file name for the download
            
//             // Append the anchor to the body (needed for Firefox)
//             document.body.appendChild(a);
            
//             // Trigger the download by simulating a click on the anchor
//             a.click();
            
//             // Clean up by removing the anchor from the document
//             a.remove();
//         } catch (error) {
//             // Log any errors that occur during the process
//             console.error("Error downloading the CSV file:", error);
//         } finally {
//             // Reset the downloading state for the CSV download
//             setIsDownloadingCsv(false);
//         }
//     };

//     return (
//         <div>
//             {/* Button that triggers the CSV download process */}
//             <button onClick={handleCsvDownload} disabled={isDownloadingCsv}>
//                 {isDownloadingCsv ? 'Downloading CSV...' : 'Download Telegram  CSV'}
//             </button>
//             {isDownloadingCsv && <p>Please wait, your CSV file is being downloaded...</p>}
//         </div>
//     );
// }

// export default TelegramDashboard;
// =============================================================================
// import React, { useState } from 'react';

// function TelegramDashboard() {
//     // State to manage the downloading status
//     const [isDownloadingZip, setIsDownloadingZip] = useState(false);

//     // Function to handle the download of the ZIP file
//     const handleZipDownload = () => {
//         // Indicate that the ZIP download is in progress
//         setIsDownloadingZip(true);

//         try {
//             // Specify the URL of the ZIP file
//             const zipFileUrl = `${process.env.PUBLIC_URL}/telegram_v1.zip`;
            
//             // Create a temporary link element for downloading the ZIP file
//             const a = document.createElement('a');
//             a.href = zipFileUrl; // Set the href to the ZIP file URL
//             a.download = 'telegram_v1.zip'; // Set the desired file name for the download
            
//             // Append the anchor to the body (needed for Firefox)
//             document.body.appendChild(a);
            
//             // Trigger the download by simulating a click on the anchor
//             a.click();
            
//             // Clean up by removing the anchor from the document
//             a.remove();
//         } catch (error) {
//             // Log any errors that occur during the process
//             console.error("Error downloading the ZIP file:", error);
//         } finally {
//             // Reset the downloading state for the ZIP download
//             setIsDownloadingZip(false);
//         }
//     };

//     return (
//         <div>
//             {/* Button that triggers the ZIP download process */}
//             <button onClick={handleZipDownload} disabled={isDownloadingZip}>
//                 {isDownloadingZip ? 'Downloading ZIP...' : 'Download ZIP'}
//             </button>
//             {isDownloadingZip && <p>Please wait, your ZIP file is being downloaded...</p>}
//         </div>
//     );
// }

// export default TelegramDashboard;
