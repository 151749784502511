// dataManipulations.js

/**
 * Slices the data based on the provided filters.
 * 
 * @param {Array} data - The data to be sliced.
 * @param {string} fromTime - The starting time for the filter.
 * @param {string} toTime - The ending time for the filter.
 * @param {string} chatGroup - The chat group filter.
 * @param {string} freeWords - The free words filter.
 * @returns {Array} - The filtered data.
 */
export function sliceDf(data, fromTime, toTime, chatGroup, freeWords) {
    let filteredData = data;

    // Filter by time range
    if (fromTime) {
        filteredData = filteredData.filter(item => new Date(item.time) >= new Date(fromTime));
    }
    if (toTime) {
        filteredData = filteredData.filter(item => new Date(item.time) <= new Date(toTime));
    }

    // Filter by chat group
    if (chatGroup) {
        filteredData = filteredData.filter(item => item.chatGroup === chatGroup);
    }

    // Filter by free words
    if (freeWords) {
        const words = freeWords.split(' ');
        filteredData = filteredData.filter(item => 
            words.some(word => item.message.includes(word))
        );
    }

    return filteredData;
}
