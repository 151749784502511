import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import ResearchScreen from "./screens/ResearchScreen";
import { useState } from "react";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <div className="App">
      {isLoggedIn ? (
        <ResearchScreen />
      ) : (
        <LoginScreen onLogin={setIsLoggedIn} />
      )}
    </div>
  );
}

export default App;
